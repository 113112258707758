import type { TokenValues } from "@silversea-ssc/dotcom-read-model-types/Tokens";
import { ReactNode } from "react";
import { renderPlaceholderValue } from "./PlaceholderValueFormatter";

export const offerPlaceholderResolver = (tokenValues: TokenValues = {}) => {
  return (placeholders: string[]): NonNullable<ReactNode> => {
    return placeholders.map((placeholder) => {
      const value = tokenValues[placeholder];
      return renderPlaceholderValue(placeholder, value);
    });
  };
};
