import React, { useMemo } from "react";
import { useLanguage } from "../utils/localization/languages/I18nContext";
import { formatDate, FormatDateFormat } from "../utils/date";
interface DateOnlyFormatProps {
  value: Date | string | undefined;
  format: FormatDateFormat;
}

const DateOnlyFormat: React.FC<
  React.PropsWithChildren<DateOnlyFormatProps>
> = ({ value, format }) => {
  const language = useLanguage();

  const formattedDate = useMemo(() => {
    if (!value) return null;

    return formatDate(value, language.ogLang, format);
  }, [format, language.ogLang, value]);

  if (!formattedDate) return null;

  return <React.Fragment>{formattedDate}</React.Fragment>;
};

export default React.memo(
  DateOnlyFormat,
  (prevProps, nextProps) => prevProps.value == nextProps.value
);
