type ConsentSettings = {
  essential?: boolean;
  performance?: boolean;
  functional?: boolean;
  targeting?: boolean;
};

export const parseConsentSettings = (m: unknown): ConsentSettings => {
  let local = m;
  if (typeof m === "string") {
    try {
      local = JSON.parse(m);
    } catch {
      local = defaultConsentSettings;
    }
  }
  if (
    local !== null &&
    typeof local === "object" &&
    "essential" in local &&
    "functional" in local &&
    "performance" in local &&
    "targeting" in local
  ) {
    const { essential, functional, performance, targeting } = local;
    if (
      typeof essential === "boolean" &&
      typeof functional === "boolean" &&
      typeof performance === "boolean" &&
      typeof targeting === "boolean"
    ) {
      return { essential, functional, performance, targeting };
    }
  }
  return defaultConsentSettings;
};

const defaultConsentSettings: ConsentSettings = {
  essential: false,
  functional: false,
  performance: false,
  targeting: false
};
