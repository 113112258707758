export { wrapPageElement } from "./wrap-page-element";

export function onRouteUpdate(_, pluginOptions) {
  // wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    const data = pluginOptions.dataLayerName
      ? window[pluginOptions.dataLayerName]
      : window.dataLayer;
    const eventName = pluginOptions.routeChangeEventName
      ? pluginOptions.routeChangeEventName
      : `gatsby-route-change`;

    data?.push({ event: eventName });
  }, 50);
}
