import { PluginOptions } from "gatsby";

export type GatsbyPlugingSSCAnalyticsCodeOptions = PluginOptions & {
  src: string;
  qaSrc: string;
};

export const generateSscAnalyticsCore = (
  src: string,
  qaSrc: string
): string => `
(function (d, t) {
  window.naq = window.naq || [];
  var scriptUrl = "${src}";
  var searchParams = new URLSearchParams(window.location.search);
  var debugFromParam, debugFromSession
  try {
    debugFromParam = (searchParams.get("gtm_debug") || "").toLowerCase()
    debugFromSession = (sessionStorage.getItem("ss_debug_mode") || "").toLowerCase()
  }
  catch (error){
  }

  var ss_debug_mode = (debugFromParam || debugFromSession || "false") == "true";
  window.ss_debug_mode = ss_debug_mode
  if (ss_debug_mode != debugFromSession) {
    try {
      sessionStorage.setItem("ss_debug_mode", ss_debug_mode.toString());
    }
    catch (error) {
    }
  }

  if(searchParams.get("skip_gtm") == "true")
    return;

  scriptUrl = ss_debug_mode ? "${qaSrc}" : "${src}";
  if (scriptUrl && scriptUrl.length > 0){
      t = d.createElement("script");
      t.setAttribute("src", scriptUrl);
      t.setAttribute("async", true);
      d.getElementsByTagName("head")[0].appendChild(t);
  }
  })(document);
  `;

export const wisperJS = `wspr=window.wspr||(()=>{let n=Object.entries,o=Date.now,s=e=>JSON.parse(JSON.stringify(e)),f={T:{},s:{},x:(1e18*Date.now()+1e15*Math.random()).toString(36),V:!1,Y:!1,A:!1,c:s,E:async(r,s)=>{s.W=s.W||{};for(var[e,t]of n(f.s[r]||[]))s.W[e]=s.W[e]||{G:!1,H:0,M:0};let i=!0;var l=[];for(let[e,a]of n(s.W||{}))l.push((async()=>{let t;if(a.G)t=!0;else{a.D=o();try{t=!0===await f.s[r][e].Q(s)}catch(e){t=!1,a.M++,a.L=JSON.stringify(e)}a.J=o(),a.H++,a.F=a.F||a.D}a.G=t,i&=t})());return await Promise.allSettled(l),s.N=i,s},I:async()=>{if(f.Y)f.A=!0;else{f.Y=!0;for(let[a,r]of n(f.T)){var s=[];for(let[t,e]of n(r.e))s.push((async()=>{await f.E(a,e).then(e=>{e.N&&f.V&&delete r.e[t]})})());await Promise.allSettled(s)}f.Y=!1,f.A&&(f.A=!1,f.I())}},P:(e,t)=>{f.T[e]=f.T[e]||{C:0,e:{}};var a=f.x+"."+f.T[e].C++;f.T[e].e[a]={id:a,f:o(),m:s(t)},f.I()},S:(e,t,a,r)=>{f.s[e]=f.s[e]||{},r=!0===r,f.s[e][t]={Q:e=>a(s(e)),U:r},f.P("#",{e:"S",O:e,w:t,U:r}),f.I()},R:()=>{f.V=!0,f.P("#",{e:"R"})}};return f.P("#",{e:"I"}),f})();`;
