import {
  ABTestExperiment,
  ABTestExperimentBase,
  ExperimentTag,
  SegmentId
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
} from "../experiments.ts";

import {
  updateHistory
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "./context-cookie.ts";

export const loadOrGenerateSegment = (
  experiment: ABTestExperimentBase,
  computeNewSegmentValue: (experiment: ABTestExperimentBase) => SegmentId,
  experiments: readonly ABTestExperiment[],
  history: ExperimentTag[]
): { segmentId: SegmentId; history: ExperimentTag[] } => {
  const cookieTag = experimentFromId(experiment.id, history);
  if (cookieTag && isSegmentValid(cookieTag)) {
    const newHistory = updateHistory(cookieTag, experiments, history);
    return { segmentId: cookieTag.segmentId, history: newHistory };
  }

  const newExperimentTag: ExperimentTag = {
    experimentId: experiment.id,
    segmentId: computeNewSegmentValue(experiment)
  };
  const newHistory = updateHistory(newExperimentTag, experiments, history);
  return { segmentId: newExperimentTag.segmentId, history: newHistory };
};

export const defaultComputeNewSegmentValue = (
  experiment: ABTestExperimentBase
): SegmentId => {
  const value = Math.random();
  return value <= experiment.weighting ? "a" : "b";
};

const isSegmentValid = (value: ExperimentTag): boolean =>
  ["a", "b"].includes(value.segmentId);

const experimentFromId = (experimentId: string, experiments: ExperimentTag[]) =>
  experiments.find((e) => e.experimentId == experimentId);
