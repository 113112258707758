import { Geolocation } from "./geolocation";
import { isAirNonAirMatch } from "../../airNonAir";
import {
  AirNonAirFilterFragment,
  AirNonAirOrLocationFilterFragment,
  LocationFilterFragment
} from "types/graphql-types";
import { isPresent } from "src/utils/checks";

interface LocationSpecificVersion<T> {
  locationFilters?: ({ locationFilter?: string; _type?: string } | undefined)[];
  content?: T;
}

const tail = <T>([_, ...tail]: T[]) => tail;

const startsWith = (longer: string[], shorter: string[]): boolean => {
  return (
    shorter.length === 0 ||
    (longer.length > 0 &&
      shorter[0].toUpperCase() === longer[0].toUpperCase() &&
      startsWith(tail(longer), tail(shorter)))
  );
};

const splitToSegments = (value: string | null) =>
  value ? value.split("/") : [];

const score = (countryPath: string | null) => {
  const segments = splitToSegments(countryPath);
  return (filter: string | undefined): number => {
    if (!filter || filter === "*") return 0;
    const filterSegments = splitToSegments(filter);
    return startsWith(segments, filterSegments) ? filterSegments.length : -1;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasContent = (content: any): boolean => {
  if (content.localized != undefined) return content.localized?.length;
  return content?.length;
};

const match = <T>(
  countryPath: string | null,
  versions: LocationSpecificVersion<T>[]
) =>
  (versions || []).map((version) => ({
    version,
    score:
      (version.locationFilters || [])
        .map((i) => score(countryPath)(i?.locationFilter))
        .sort((a, b) => b - a)[0] ?? 0
  }));

export const getBestMatch = <T>(
  countryPath: string | null,
  versions: LocationSpecificVersion<T>[]
): T | null => {
  const matches = match(countryPath, versions);
  return (
    matches
      .filter((x) => hasContent(x.version.content))
      .filter((x) => x.score >= 0)
      .sort((a, b) => b.score - a.score)
      .map((x) => x.version.content)[0] || null
  );
};

const isMatch = (
  countryPath: string | undefined,
  locationFilter: string
): boolean => {
  return score(countryPath || "")(locationFilter) >= 0;
};

export const matchesAny = (
  filters?: (AirNonAirOrLocationFilterFragment | undefined)[],
  geolocation?: Geolocation
): boolean => {
  if (!filters || !(filters.length > 0)) return true;
  const { countryCode, countryPath } = geolocation || {};

  return filters.some((filter) => {
    if (filter) {
      const type = "_type" in filter ? filter._type : undefined;
      if (
        filter.__typename === "SanityAirNonAirFilter" ||
        type === "airNonAirFilter"
      ) {
        const { airNonAirFilter } = filter as AirNonAirFilterFragment;
        return isAirNonAirMatch(countryCode, airNonAirFilter);
      } else if (
        filter.__typename === "SanityLocationFilter" ||
        type === "locationFilter"
      ) {
        const { locationFilter } = filter as LocationFilterFragment;
        return countryCode && isMatch(countryPath, locationFilter || "");
      }
    }

    return false;
  });
};

export const getLocationSpecificContent = <T>(
  geolocation: Geolocation,
  content: (LocationSpecificVersion<T> | undefined)[] | undefined
): T | null =>
  getBestMatch(geolocation.countryPath || "", content?.filter(isPresent) || []);
