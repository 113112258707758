import { LayoutType } from "types/graphql-types";

export type HeaderTypes =
  | "light-nav-bar"
  | "light-nav-bar-v2"
  | "light-nav-bar-inbound-experiment"
  | "corporate-incentives-nav-bar"
  | "default-nav-bar";

export type FooterTypes = "footer" | "footer-light";

export type ComponentsTypes =
  | "subscribe-to-newsletter"
  | "subscribe-to-newsletter-popup";

type LayoutConfig = {
  stickyHeader: boolean;

  header: HeaderTypes;
  footer: FooterTypes;
};

const layoutConfig: Record<LayoutType, LayoutConfig> = {
  LIGHT: {
    header: "light-nav-bar",
    footer: "footer",
    stickyHeader: false
  },
  LIGHTV2: {
    stickyHeader: true,
    header: "light-nav-bar-v2",
    footer: "footer-light"
  },
  INBOUND_EXPERIMENT: {
    stickyHeader: true,
    header: "light-nav-bar-inbound-experiment",
    footer: "footer"
  },
  CORPORATE_INCENTIVES: {
    stickyHeader: false,
    header: "corporate-incentives-nav-bar",
    footer: "footer"
  },
  DEFAULT: {
    header: "default-nav-bar",
    footer: "footer",
    stickyHeader: false
  }
};

export const layoutConfigFor = (type: LayoutType) =>
  layoutConfig[type] || layoutConfig.DEFAULT;
