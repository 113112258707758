module.exports = [{
      plugin: require('../src/gatsby/plugins/gatsby-plugin-rollbar/gatsby-browser.tsx'),
      options: {"plugins":[],"accessToken":"0aa9fe7a3ce34a7ea4be495dd5e70727","enabled":true,"captureIp":"anonymize","stackTraceLimit":100,"payload":{"environment":"production"},"ignoredMessages":["jQuery is missing","TypeError: Failed to construct 'URL': Invalid URL","Can't find variable Intl","Can't find variable: Intl","(unknown): Script error.","Can't find variable rollbarError","Error: We couldn't load","Cannot read property classList of undefined/null expression"],"hostSafeList":["silversea.com","netlify.app"]},
    },{
      plugin: require('../src/gatsby/plugins/gatsby-plugin-ssc-analytics-core/gatsby-browser.tsx'),
      options: {"plugins":[],"src":"https://analytics.silversea.com/index.js?version=1","qaSrc":"https://qa--silversea-com-analytics-core.netlify.app/index.js?version=1"},
    },{
      plugin: require('../src/gatsby/plugins/gatsby-plugin-custom-google-tagmanager/gatsby-browser.tsx'),
      options: {"plugins":[],"id":"GTM-5CN7PQ8","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
