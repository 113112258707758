import { Language, LanguageCode, SanityCode } from "./types";

export const english: Language = {
  code: "en",
  path: "",
  ogLang: "en",
  name: "English",
  shortName: "ENG",
  sanityCode: "en",
  sanityRawCode: "_rawEn"
};

export const defaultLanguage = english;

export const spanish: Language = {
  code: "es",
  path: "es",
  ogLang: "es",
  name: "Español",
  shortName: "ESP",
  sanityCode: "es",
  sanityRawCode: "_rawEs"
};

export const brazilianPortuguese: Language = {
  code: "pt-br",
  path: "pt-br",
  ogLang: "pt-BR",
  name: "Português",
  shortName: "POR",
  sanityCode: "pt_br",
  sanityRawCode: "_rawPtBr"
};

export const german: Language = {
  code: "de",
  path: "de",
  ogLang: "de",
  name: "Deutsch",
  shortName: "DEU",
  sanityCode: "de",
  sanityRawCode: "_rawDe"
};

export const french: Language = {
  code: "fr",
  path: "fr",
  ogLang: "fr",
  name: "Français",
  shortName: "FRA",
  sanityCode: "fr",
  sanityRawCode: "_rawFr"
};

export const languages: Language[] = [
  defaultLanguage,
  spanish,
  brazilianPortuguese,
  german,
  french
];

export const pickTranslation = <T>(
  translations: { [key in SanityCode]?: T } | undefined | null,
  language: Language
): T | undefined => {
  return (
    (translations &&
      (translations[language.sanityCode] ||
        translations[language.code] ||
        translations[defaultLanguage.sanityCode])) ||
    undefined
  );
};

export const languageCodeFromSanityCode = (
  sanityCode: SanityCode
): LanguageCode => {
  if (sanityCode == "pt_br") return "pt-br";

  return sanityCode;
};

export const languageFromCode = (languageCode: LanguageCode): Language =>
  languages.find((l) => l.code == languageCode) || defaultLanguage;
