import { useEffect, useState } from "react";
import type { WindowLocation } from "@reach/router";
import { checkIfLocalStorageIsSupportedByBrowser } from "./local-storage-item";

const featureEnabled = (name: string) => `${name}=true`;
const featureDisabled = (name: string) => `${name}=false`;

const activeFeatureFlags = [] as const;
type ActiveFeatureFlags = (typeof activeFeatureFlags)[number];

// eslint-disable-next-line import/no-unused-modules
export const useFeatureFlag = (name: ActiveFeatureFlags) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const isLocalStorageEnabled = checkIfLocalStorageIsSupportedByBrowser();

  useEffect(() => {
    if (window.location.search.includes(featureEnabled(name))) {
      if (isLocalStorageEnabled) window.localStorage.setItem(name, "true");
      setIsEnabled(true);
      return;
    }
    if (window.location.search.includes(featureDisabled(name))) {
      if (isLocalStorageEnabled) window.localStorage.removeItem(name);
      setIsEnabled(false);
      return;
    }
    if (isLocalStorageEnabled && window.localStorage.getItem(name) === "true") {
      setIsEnabled(true);
      return;
    }
  }, []);

  return isEnabled;
};

// eslint-disable-next-line import/no-unused-modules
export const isDev = (location: WindowLocation | undefined) =>
  process.env.NODE_ENV == "development" && location?.hostname == "localhost";

export const isEnvFeatureEnabled = (
  envVariable: string | undefined
): boolean => {
  return (envVariable || "false").toLowerCase() === "true";
};

// export const isNewStrategicPricingEnabled = () => {
//   return true;
// };
