import { stripIndent } from "common-tags";

export type GTMPluginOptions = {
  id: string;
  includeInDevelopment: boolean;
  gtmAuth: string;
  gtmPreview: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultDataLayer: any;
  dataLayerName: string;
  selfHostedOrigin: string;
};

export const generateGTM = ({
  id,
  environmentParamStr,
  dataLayerName,
  selfHostedOrigin
}) => stripIndent`
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    '${selfHostedOrigin}/gtm.js?id='+i+dl+'${environmentParamStr}';f.parentNode.insertBefore(j,f);
    })(window,document,'script','${dataLayerName}', '${id}');`;

export const generateDefaultDataLayer = (dataLayer, dataLayerName) => {
  let result = `window.${dataLayerName} = window.${dataLayerName} || [];`;

  if (dataLayer.type === `function`) {
    result += `window.${dataLayerName}.push((${dataLayer.value})());`;
  } else {
    if (dataLayer.type !== `object` || dataLayer.value.constructor !== Object) {
      console.error(
        `Oops the plugin option "defaultDataLayer" should be a plain object. "${dataLayer}" is not valid.`
      );
    }

    result += `window.${dataLayerName}.push(${JSON.stringify(
      dataLayer.value
    )});`;
  }

  return stripIndent`${result}`;
};
