const specs = {
  Air: [
    "US",
    "CA",
    "GG",
    "IM",
    "JE",
    "IE",
    "DE",
    "AT",
    "CH",
    "IT",
    "ES",
    "FR",
    "PT",
    "SE",
    "NO",
    "FI",
    "NL",
    "DK",
    "BE",
    "AU"
  ],
  UK: ["GB"],
  NZ: ["NZ"]
};

const allAirCodes = Object.keys(specs).reduce<string[]>(
  (a, k) => [...a, ...specs[k]],
  []
);

export const isAirNonAirMatch = (countryCode, filter) => {
  if (filter === "NonAir") return !allAirCodes.includes(countryCode);
  return specs[filter] && specs[filter].includes(countryCode);
};
