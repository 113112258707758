import useLiveSanityContent, {
  sanityLocalized,
  UseLiveSanityContentResponse
} from "src/ssc-api/useLiveSanityContent";
import { useLanguage } from "src/utils/localization/languages/I18nContext";
import { PartialSanityImageType } from "@silversea-ssc/ui-components-react";

export type KeepBookingCruiseContent = {
  cardImage: PartialSanityImageType;
  data: {
    cruiseCode: string;
    departureDate: string;
    departurePort:
      | {
          name: { localized: string };
        }
      | undefined;
    arrivalDate: string;
    arrivalPort:
      | {
          name: { localized: string };
        }
      | undefined;
    days: number;
    suiteName?: {
      localized?: string;
    };
  };
};

type QueryResult = {
  cruise: KeepBookingCruiseContent;
  specialCruise: KeepBookingCruiseContent;
};

const suiteNameQuery = `
"suiteName": ship -> {
  _id,
  "version": versions[(validFrom <= ^.^.departureDate)] | order(validFrom asc)[-1] {
    "suite": suites[$suiteCategoryCode in @.codes] {
      category->{
        ${sanityLocalized`name`}
      }
    }[0]
  }
}.version.suite.category.name
`;

export const useKeepBookingContent = (
  cruiseCode?: string,
  suiteCategoryCode?: string
): UseLiveSanityContentResponse<KeepBookingCruiseContent> => {
  const language = useLanguage();

  const query = cruiseCode
    ? `
    {
      "cruise": *[_type == "cruise" && !(_id in path("drafts.*")) && data->cruiseCode == $cruiseCode && data->isVisible == true][0] {
        cardImage {
          asset -> {
            _id,
          }
        },
        data -> {
          cruiseCode,
          days,
          departureDate,
          departurePort -> {
            ${sanityLocalized`name`}
          },
          arrivalDate,
          arrivalPort -> {
            ${sanityLocalized`name`}
          },
          ${suiteCategoryCode ? suiteNameQuery : ""}
        }
      },
      "specialCruise": *[_type == "specialVoyage" && !(_id in path("drafts.*")) && data->cruiseCode == $cruiseCode && isVisible == true][0] {
        "cardImage": card.image {
          asset -> {
            _id,
          }
        },
        data -> {
          cruiseCode,
          days,
          departureDate,
          departurePort -> {
            ${sanityLocalized`name`}
          },
          arrivalDate,
          arrivalPort -> {
            ${sanityLocalized`name`}
          },
          ${suiteCategoryCode ? suiteNameQuery : ""}
        }
      },
    }  `
    : undefined;

  const { isLoading, isError, data, status } =
    useLiveSanityContent<QueryResult>(query, {
      $lang: language.sanityCode,
      $cruiseCode: cruiseCode || "",
      ...(suiteCategoryCode ? { $suiteCategoryCode: suiteCategoryCode } : {})
    });

  const cruiseContent = data?.result?.cruise || data?.result?.specialCruise;

  return {
    isLoading,
    isError,
    data: { result: cruiseContent, query: data?.query },
    status
  };
};
