import { getCookie, setCookie } from "src/utils/cookies";
import {
  defaultComputeNewSegmentValue,
  loadOrGenerateSegment
} from "../edge-lambda/segment";
import {
  ComponentABTestCookieName,
  ABTestExperimentComponent,
  ExperimentTag,
  activeExperiments,
  onlyABTestExperimentComponents
} from "../experiments";
import { decodeHistory, encodeHistory } from "../history";

const abTestClientSideHandler = (
  experiments: readonly ABTestExperimentComponent[]
): boolean => {
  const history = getCookieFromDocument();
  const newHistory = experiments.reduce((acc, e) => {
    const { history } = loadOrGenerateSegment(
      e,
      defaultComputeNewSegmentValue,
      experiments,
      acc
    );

    return history;
  }, history);

  setCookie(ComponentABTestCookieName, encodeHistory(newHistory), {
    path: "/",
    maxAge: 60 * 60 * 24 * 365 // 1 year
  });

  return true;
};

const getCookieFromDocument = (): ExperimentTag[] => {
  const raw = getCookie(ComponentABTestCookieName);
  if (!raw) return [];

  try {
    return decodeHistory(raw);
  } catch (_e) {
    return [];
  }
};

export const assignAbTestSegmentToUser = () => {
  const componentBasedExperiments =
    onlyABTestExperimentComponents(activeExperiments);

  if (componentBasedExperiments.length == 0) return;

  abTestClientSideHandler(componentBasedExperiments);
};
