/* eslint-disable import/no-unused-modules */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export type Nullable<T> = T | null;

export function orElse<T>(x: Nullable<T>, defaultVal: T): T {
  return isNullOrUndefined(x) ? defaultVal : (x as T);
}

export function isNull(x: any): x is null {
  return x === null;
}

export function isNotNull<T>(x: T): x is T {
  return x !== null;
}

export function isUndefined(x: any): x is undefined {
  return x === undefined;
}

export function isDefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

export function isNullOrUndefined(x: any): x is undefined | null {
  return isNull(x) || isUndefined(x);
}

export function isNotNullorUnDefined<T>(x: T | undefined | null): x is T {
  return isNotNull(x) && isDefined(x);
}

export function isPresent<T>(x: T | undefined | null): x is T {
  return isNotNullorUnDefined(x);
}

export function arrayIsNotEmpty<T>(x: T[] | null | undefined): x is T[] {
  return isNotNullorUnDefined(x) && x.length > 0;
}

export function arrayIsEmpty<T>(x: T[] | null | undefined): x is T[] {
  return !arrayIsNotEmpty(x);
}
