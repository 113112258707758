import React, { createContext } from "react";
import { I18nProvider as LinguiProvider } from "@lingui/react";
import { defaultLanguage } from "src/utils/localization/languages/languages";
import { Language } from "src/utils/localization/languages/types";
import { i18n } from "@lingui/core";

import { messages as enMessages } from "../../../locales/en/messages";
import { messages as esMessages } from "../../../locales/es/messages";
import { messages as frMessages } from "../../../locales/fr/messages";
import { messages as deMessages } from "../../../locales/de/messages";
import { messages as ptBrMessages } from "../../../locales/pt-br/messages";

i18n.load({
  en: enMessages,
  es: esMessages,
  fr: frMessages,
  de: deMessages,
  "pt-br": ptBrMessages
});

const LanguageContext = createContext<Language>(defaultLanguage as Language);
interface I18nProviderProps {
  language?: Language;
}

export const I18nProvider: React.FC<
  React.PropsWithChildren<I18nProviderProps>
> = ({ language = defaultLanguage, children }) => {
  // const messages = useMemo(
  //   () =>
  //     match(language.code)
  //       .with("en", () => enMessages)
  //       .with("es", () => esMessages)
  //       .with("fr", () => frMessages)
  //       .with("de", () => deMessages)
  //       .with("pt-br", () => ptBrMessages)
  //       .exhaustive(),
  //   [language.code]
  // );
  if (i18n.locale !== language.code) {
    i18n.activate(language.code);
  }
  // if (i18n.locale !== language.code) {
  //   console.debug("activate locale. Was", i18n.locale, "now", language.code);
  //   i18n.loadAndActivate({ locale: language.code, messages: messages });
  // }
  return (
    <LinguiProvider i18n={i18n}>
      <LanguageContext.Provider value={language}>
        {children}
      </LanguageContext.Provider>
    </LinguiProvider>
  );
};

export function useLanguage(): Language {
  return React.useContext(LanguageContext);
}

// async function dynamicActivate(locale: LanguageCode) {
//   const { messages } = await import(
//     /* webpackMode: "lazy", webpackChunkName: "locales-[request]" */ `../../../locales/${locale}/messages`
//   );
//   i18n.load(locale, messages);
//   i18n.activate(locale);
// }
