import {
  ABTestExperimentBase,
  ExperimentTag,
  SegmentId
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "./experiments.ts";

export const cleanupHistory = (
  history: ExperimentTag[],
  experiments: readonly ABTestExperimentBase[]
): ExperimentTag[] => {
  const activeExperimentIds = experiments.map((e) => e.id);
  return history.filter((h) => activeExperimentIds.includes(h.experimentId));
};

export const decodeHistory = (string: string): ExperimentTag[] => {
  return decodeURIComponent(string)
    .split("&&")
    .filter((x) => !!x)
    .map((r) => {
      const values = r.split("||", 2);

      return {
        experimentId: values[0],
        segmentId: values[1] as SegmentId
      };
    });
};

export const encodeHistory = (values: ExperimentTag[]): string =>
  encodeURIComponent(
    values.map((e) => `${e.experimentId}||${e.segmentId}`).join("&&")
  );

export const replaceOrAppendValue = (
  history: ExperimentTag[],
  value: ExperimentTag
) => [...history.filter((x) => x.experimentId != value.experimentId), value];
