const legacyUrlForBVersion = (aUrl: string): string =>
  aUrl.replace(".html", "-b.html");

const urlForBVersion = (aUrl: string): string => `${aUrl}-b`;

export const urlsAandBVersions = (urls: string[]): string[] =>
  urls.flatMap((url) =>
    Array.from(
      new Set([
        url,
        urlForBVersion(url),
        legacyUrl(url),
        legacyUrlForBVersion(legacyUrl(url))
      ])
    )
  );

export const isLegacyUrl = (path: string): boolean => path.endsWith(".html");

function legacyUrl(path: undefined | null): undefined;
function legacyUrl(path: string): string;
function legacyUrl(path: string | undefined | null): string | undefined {
  if (!path) return undefined;
  if (path == "/") return path;
  if (path.endsWith(".html")) return path;

  return `${path}.html`;
}

export { legacyUrl };

// NOTE: this is important because some marketing campaigns might have the wrong URL
// ie: a URL with .html/?params=params instead of .html?params=params
export const sanitizePathName = (pathName: string): string => {
  return pathName.replace(".html/", ".html");
};
