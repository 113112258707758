import { GatsbyBrowser } from "gatsby";
import RootElement from "./RootElement";
import React from "react";
import { GTMPluginOptions } from "./utils";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = (
  { element },
  options
) => {
  return (
    <React.Fragment>
      <RootElement
        key="root-gtm"
        {...(options as unknown as GTMPluginOptions)}
      />
      {element}
    </React.Fragment>
  );
};
