import React from "react";

import DefaultLayout from "../layouts/DefaultLayout";
import { I18nProvider } from "../utils/localization/languages/I18nContext";
import { getCurrentGeolocation } from "../utils/localization/geolocation/geolocationLoader";
import { sendPageAnalytics } from "../utils/analytics";
import { isSSR } from "../utils/ssr";

//import Modal from "react-modal";
import { SitePageContext } from "types/graphql-types";
import { LanguageCode } from "../utils/localization/languages/types";
import { languageFromCode } from "../utils/localization/languages/languages";
import { UserIdProvider } from "src/utils/UserIdProvider";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wrapPageElement = ({ element, props }) => {
  const pageContext = props.pageContext as SitePageContext;
  const { modal = false } = pageContext;
  const language = languageFromCode(pageContext.languageCode as LanguageCode);
  const trackingParams = pageContext.trackingParams;

  if (Object.keys(pageContext).length == 0) {
    //"/dev-404-page/"
    return (
      <React.Fragment>
        <I18nProvider language={language}>{element}</I18nProvider>
      </React.Fragment>
    );
  }

  if (modal)
    return (
      <React.Fragment>
        <I18nProvider language={language}>{element}</I18nProvider>
      </React.Fragment>
    );

  if (!isSSR()) {
    let geo_country_code = "Failed";
    let geo_market = "Failed";

    getCurrentGeolocation()
      .then(({ detectedCountryCode, market }) => {
        geo_country_code = detectedCountryCode || "Failed";
        geo_market = market || "Failed";
      })
      .catch((e) => {
        if (window.rollbar) window.rollbar.error(e);
        geo_country_code = "Failed";
        geo_market = "Failed";
      })
      // we want to trigger page view even if the geolocation API failed
      .finally(() => {
        sendPageAnalytics({
          pageCategory1: trackingParams?.pageCategory1 || "Unavailable",
          pageCategory2: trackingParams?.pageCategory2 || "Unavailable",
          pageType: trackingParams?.pageType || "Unavailable",
          language: language.ogLang || "Unavailable",
          countryCode: geo_country_code || "Unavailable",
          market: geo_market || "Unavailable"
        });
      });
  }
  return (
    <React.Fragment>
      <UserIdProvider>
        <I18nProvider language={language}>
          <DefaultLayout {...props}>{element}</DefaultLayout>
        </I18nProvider>
      </UserIdProvider>
    </React.Fragment>
  );
};
