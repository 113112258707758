import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from "react";
import { parseConsentSettings } from "./consentSettings";
import { deleteCookie, getCookie, setCookie } from "./cookies";
import { v4 as uuidv4 } from "uuid";
import { isSSR } from "./ssr";

const COOKIE_NAME = "silversea_com_user_id";

type UserIdContextValue = string | undefined;
const UserIdContext = createContext<UserIdContextValue>(undefined);

export const UserIdProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [userId, setUserId] = useState<UserIdContextValue>();
  const [, forceProviderReload] = useState(false);

  if (isWsprMissing()) {
    console.info("wspr is missing, trying to polyfill it");
    Object.defineProperty(window, "wspr", {
      set(v: unknown) {
        this.__wspr__ = v;
        forceProviderReload(true);
      },
      get(): unknown {
        return this.__wspr__;
      }
    });
  }

  const hasWspr = !isSSR() && !!window.wspr;

  useEffect(() => {
    if (!hasWspr) return;

    window.wspr.S("consent_settings", "useUserId", ({ m }) => {
      const consentSettings = parseConsentSettings(m);
      const canUseCookie = consentSettings.functional === true;

      if (canUseCookie) {
        const cookie = getCookie(COOKIE_NAME);
        const value = cookie ?? uuidv4();
        const threeMonths = 60 * 60 * 24 * 30 * 3; //TODO check if it really works

        setCookie(COOKIE_NAME, value, { maxAge: threeMonths });
        setUserId(value);
      } else {
        deleteCookie(COOKIE_NAME);
        setUserId(undefined);
      }
      return true;
    });
  }, [hasWspr]);

  return (
    <UserIdContext.Provider value={userId}>{children}</UserIdContext.Provider>
  );
};

export function useUserId(): string | undefined {
  return useContext(UserIdContext);
}

const isWsprMissing = () =>
  !isSSR() &&
  !window.wspr &&
  !Object.prototype.hasOwnProperty.call(window, "wspr");
