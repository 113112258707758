import {
  PageABTestCookieName,
  ABTestExperimentBase,
  ExperimentTag
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "../experiments.ts";

import {
  cleanupHistory,
  decodeHistory,
  encodeHistory,
  replaceOrAppendValue
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "../history.ts";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { NetlifyContext } from "./handler.ts";

export const getCookieFromContext = (
  context: NetlifyContext
): ExperimentTag[] => {
  const raw = context.cookies.get(PageABTestCookieName);

  try {
    return decodeHistory(raw);
  } catch (_e) {
    return [];
  }
};

export const updateHistory = (
  value: ExperimentTag,
  experiments: readonly ABTestExperimentBase[],
  history: ExperimentTag[]
): ExperimentTag[] => {
  const newHistory = replaceOrAppendValue(
    cleanupHistory(history, experiments),
    value
  );
  return newHistory;
};

export const storeHistory = (
  history: ExperimentTag[],
  context: NetlifyContext
) =>
  context.cookies.set({
    name: PageABTestCookieName,
    value: encodeHistory(history),
    path: "/",
    maxAge: 60 * 60 * 24 * 365 // 1 year
  });
