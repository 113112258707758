import { wrapPageElement } from "./src/gatsby-config/wrapPageElement";
import { wrapRootElement } from "./src/gatsby-config/wrapRootElement";
import { getCurrentGeolocation } from "./src/utils/localization/geolocation/geolocationLoader";
import { GatsbyBrowser } from "gatsby";
import { setScrollOffset, scrollToSection } from "./src/utils/inPageNavigation";
import { assignAbTestSegmentToUser } from "./src/ab-test-experiments/client/client-side-handler";

// used to fix ios 100vh when toolbar is shown
function computeReal100vh() {
  const doc = document.documentElement;
  let prevClientHeight: number;
  const customVar = "--vh";
  function handleResize() {
    const clientHeight = doc.clientHeight;
    if (clientHeight === prevClientHeight) return;
    requestAnimationFrame(function updateViewportHeight() {
      doc.style.setProperty(customVar, clientHeight * 0.01 + "px");
      prevClientHeight = clientHeight;
    });
  }
  handleResize();
  return handleResize;
}

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  assignAbTestSegmentToUser();

  getCurrentGeolocation();

  window.addEventListener("resize", () => {
    computeReal100vh();
    setScrollOffset();
  });
  computeReal100vh();
  setScrollOffset();
};

// export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
//   // const computedOffset = computeScrollHeaderOffset();
//   // const hash = location.hash;
//   // const selector = hash ? hash.slice(1) : undefined;
//   // const validElement = selector ? document.getElementById(selector) : null;
//   // console.debug("onRouteUpdate", validElement, computedOffset);
//   // if (validElement) {
//   //   scrollToSection(validElement as HTMLDivElement, computedOffset);
//   // }
// };

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location }
}) => {
  setScrollOffset();

  const hash = location.hash;
  const selector = hash ? hash.slice(1) : undefined;
  const validElement = selector ? document.getElementById(selector) : null;

  if (validElement) {
    setTimeout(() => {
      scrollToSection(validElement as HTMLDivElement);
    }, 500);

    return false;
  }
  return true;
};

export { wrapRootElement, wrapPageElement };
