import React, {
  Component,
  ErrorInfo,
  PropsWithChildren,
  ReactNode
} from "react";
import { ErrorBoundary as RollbarErrorBoundary } from "@rollbar/react";

export class IngoreErrorBoundary extends Component<PropsWithChildren<unknown>> {
  state = {
    error: null
  };

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  componentDidCatch(_error: Error, _errorInfo: ErrorInfo): void {
    //
  }

  render(): ReactNode {
    if (this.state.error) {
      // You can render any custom fallback UI
      return <></>;
    }
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default RollbarErrorBoundary;
