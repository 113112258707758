import React from "react";
import { Provider } from "@rollbar/react";
import { GatsbyBrowser } from "gatsby";
import Rollbar, { Configuration } from "rollbar";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = (
  { element },
  options
) => {
  return <Provider config={options as Configuration}>{element}</Provider>;
};

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  //expose Rollbar class to allow extenral libraries (ie analytics) to create they own instances of Rollbar
  window.Rollbar = Rollbar;
};
