import {
  checkIfLocalStorageIsSupportedByBrowser,
  makeLocalStorageItem
} from "src/utils/local-storage-item";

const ItemName = "keep-booking-counter" as const;
const MaxDeleteAttemptCount = 3;

type KeepBookingCounter = {
  cruiseCode: string;
  deleteAttemptCount: number;
};

type AllKeepBookingCounters = Record<string, KeepBookingCounter | undefined>;

export const checkAndUpdateCounter = (cruiseCode: string): boolean => {
  if (!checkIfLocalStorageIsSupportedByBrowser()) return true;

  const localStorageItem =
    makeLocalStorageItem<AllKeepBookingCounters>(ItemName);

  const allCounters = localStorageItem.get() || {};
  const existing = allCounters[cruiseCode];

  const newOrFound = existing
    ? { ...existing }
    : { cruiseCode, deleteAttemptCount: 0 };

  newOrFound.deleteAttemptCount++;
  const updatedCounter = { [cruiseCode]: newOrFound };
  // NOTE: to support multiple keep booking it would be
  //const updatedCounter = { ...allCounters, ...{ [cruiseCode]: newOrFound } };
  localStorageItem.set(updatedCounter);

  return newOrFound.deleteAttemptCount >= MaxDeleteAttemptCount;
};

export const resetCounter = (cruiseCode: string): void => {
  if (!checkIfLocalStorageIsSupportedByBrowser()) return;

  const localStorageItem =
    makeLocalStorageItem<AllKeepBookingCounters>(ItemName);

  const allCounters = localStorageItem.get() || {};
  delete allCounters[cruiseCode];
  localStorageItem.set(allCounters);
};
