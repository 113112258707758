import { SyntheticEvent } from "react";
import { isSSR } from "../ssr";

import { pageExperimentTagFor } from "src/ab-test-experiments/client/experiment-tag";

export interface PageTrackingParams {
  pageCategory1: string | undefined;
  pageCategory2: string | undefined;
  pageType: string | undefined;
}

interface PageAnalyticsDetails {
  language: string;
  pageCategory1?: string;
  pageCategory2?: string;
  pageType?: string;
  countryCode?: string;
  market?: string;
}

const getAAMarket = (
  market: string | undefined,
  countryCode: string | undefined
): string | undefined => {
  if (countryCode === "US" || countryCode === "CA") return "US";
  if (market === "FT") return "LAM";
  if (market === "AS") return "AP";
  if (market === "EU") return "EMEA";
  return market;
};

export const sendPageAnalytics = ({
  pageCategory1,
  pageCategory2,
  pageType,
  language,
  countryCode,
  market
}: PageAnalyticsDetails): void => {
  if (isSSR()) return;

  const abTestInfo = getABTestTrackingInfo(
    window.location.href.split("?")[0],
    countryCode
  );

  const details = {
    track_current_page: window.location.href.split("?")[0],
    page_cat1: pageCategory1,
    page_cat2: pageCategory2,
    track_page_type: pageType,
    user_language: language,
    user_country: countryCode,
    user_geo_adwords: getAAMarket(market, countryCode),
    ...abTestInfo
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(details);

  window.wspr?.P("pageload_raw", details);
  // dispatchTrackingEvent("page-view-tracking-data-available", details);
};

type ABTestTrackingInfo = {
  experiment_id: string;
  segment_id: string;
};

const getABTestTrackingInfo = (
  url: string,
  countryCode: string | undefined
): { ab_test: ABTestTrackingInfo } | undefined => {
  const pageExperiment = pageExperimentTagFor(new URL(url), countryCode);
  if (!pageExperiment) return undefined;

  return {
    ab_test: {
      experiment_id: pageExperiment.experimentId,
      segment_id: pageExperiment.segmentId
    }
  };
};

export const dispatchTrackingEvent = (
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  detail?: any,
  domEvent?: SyntheticEvent
): void => {
  if (!isSSR()) {
    const trackingEvent = new CustomEvent(eventName, {
      bubbles: true,
      cancelable: true,
      detail: detail
    });

    const dispatchingElement =
      domEvent && domEvent.target ? domEvent.target : document;

    dispatchingElement.dispatchEvent(trackingEvent);
  }
};
