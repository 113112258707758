// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { NetlifyContext, NetlifyRequest } from "./handler.ts";

export const experimentActiveInCurrentCountry = <
  T extends { _type: string; iso2Countries?: string[] }
>(
  experiment: T,
  detectedCountryCode?: string | undefined
): boolean => {
  if (experiment._type === "component") return true;

  if (!experiment.iso2Countries || experiment.iso2Countries.length === 0)
    // if no countries are specified, the experiment is active everywhere
    return true;

  if (!detectedCountryCode) {
    return false;
  }

  return experiment.iso2Countries.includes(detectedCountryCode);
};

export const userCountryCodeFromContextOrOverride = (
  req: NetlifyRequest,
  context: NetlifyContext
): string | undefined => {
  const requestURL = new URL(req.url);
  const overriddenCountryCode = requestURL.searchParams.get("country");

  return overriddenCountryCode || context.geo.country?.code;
};
