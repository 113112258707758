import type { ReactElement } from "react";

function capitalize(string: undefined): undefined;
function capitalize(string: string): string;
function capitalize(string: string | undefined): string | undefined {
  if (!string) return undefined;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function titleCase(string: undefined): undefined;
function titleCase(string: string): string;
function titleCase(string: string | undefined): string | undefined {
  if (!string) return undefined;

  return string.toLowerCase().replace(/\S+/g, (word) => {
    return capitalize(word);
  });
}

function snakeCase(string: undefined): undefined;
function snakeCase(string: string): string;
function snakeCase(string: string | undefined): string | undefined {
  if (!string) return undefined;

  return string
    .trim()
    .toLowerCase()
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.join("_");
}

function stringPresent(content: string | null | undefined): content is string {
  if (content == null || content == undefined) return false;

  return content.trim() != "";
}

function isString(x: string | ReactElement | undefined): x is string {
  return typeof x == "string";
}

function fixhyphens(text: string): string {
  return text.replace(/(\S+)-(\S+)/g, "$1&#8288;-&#8288;$2");
}

export const NON_BREKING_SPACE = "\u00a0";

// use unicode non breaking space to prevent the port name to be splitted in multiple rows
export const nonBreakingSpaceString = (name: string) =>
  name.replace(/ /g, NON_BREKING_SPACE);

export const splitCodesStringList = (list: string): string[] => {
  return (
    list
      .toUpperCase()
      .split(/[\r?\n,]+/)
      .map((c) => c.trim())
      .filter((c) => c && c != "") || []
  );
};

export {
  capitalize,
  titleCase,
  snakeCase,
  stringPresent,
  fixhyphens,
  isString
};
