import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GeolocationProvider } from "../utils/localization/geolocation/GeolocationContext";
import { SkeletonTheme } from "react-loading-skeleton";
import sanityLocation from "../utils/sanityLocation";
import {
  SanityClientConfigContext,
  Theme
} from "@silversea-ssc/ui-components-react";
import { GatsbyBrowser, GatsbySSR } from "gatsby";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wrapRootElement:
  | GatsbyBrowser["wrapRootElement"]
  | GatsbySSR["wrapRootElement"] = ({ element }) => {
  return (
    <SanityClientConfigContext.Provider value={sanityLocation}>
      <QueryClientProvider client={queryClient}>
        <GeolocationProvider>
          <Theme>
            <SkeletonTheme baseColor="#e5e5e6" highlightColor="#D1D1D1">
              {element}
            </SkeletonTheme>
          </Theme>
        </GeolocationProvider>
      </QueryClientProvider>
    </SanityClientConfigContext.Provider>
  );
};
