import fetchRetry from "fetch-retry";

interface HttpResponse<T> extends Response {
  parsedBody?: T;
}
export async function jsonFetch<T>(request: RequestInfo): Promise<T> {
  const response: HttpResponse<T> = await fetchRetry(fetch, {
    retries: 4,
    retryDelay: 400
  })(request, {
    headers: {
      Accept: "application/json"
    }
  });
  return response.json();
}
