import React, {
  PropsWithChildren,
  startTransition,
  useEffect,
  useState
} from "react";
import { Geolocation } from "src/utils/localization/geolocation/geolocation";
import { getCurrentGeolocation } from "./geolocationLoader";
import useIsMounted from "src/useIsMounted";

const defaultState: Geolocation = {
  countryCodeOverridden: false,
  countryCode: undefined,
  countryCodeISO3: undefined,
  defaultCurrency: "USD",
  market: undefined,
  phone: undefined,
  unit: undefined,
  airCountry: true,
  countryPath: undefined
};

const GeolocationContext = React.createContext(defaultState);

const GeolocationProvider: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const isMounted = useIsMounted();
  const [value, setValue] = useState(defaultState);

  useEffect(() => {
    if (isMounted()) {
      getCurrentGeolocation().then((value) => {
        startTransition(() => setValue(value));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GeolocationContext.Provider value={value}>
      {children}
    </GeolocationContext.Provider>
  );
};

export function useGeolocation(): Geolocation {
  return React.useContext(GeolocationContext);
}

export default GeolocationContext;
export { GeolocationProvider };
