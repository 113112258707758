import { msg } from "@lingui/core/macro";
import { useLingui } from "@lingui/react/macro";

import React, { ReactNode } from "react";
import DateOnlyFormat from "components/DateOnlyFormat";
import { FormatDateFormat, toUTCDate } from "src/utils/date";
import { match, P } from "ts-pattern";
import { useGeolocation } from "src/utils/localization/geolocation/GeolocationContext";
import { Currency } from "src/utils/localization/currency";
import {
  AggregationType,
  TokenValueEncoded,
  TokenValuePercentage
} from "@silversea-ssc/dotcom-read-model-types/Tokens";
import { isPresent } from "src/utils/checks";

const numberFormatOptions = (currency: string): Intl.NumberFormatOptions => ({
  style: "currency",
  currency: currency,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

type AmountPrefixProps = {
  aggregationType: AggregationType;
  aggregatedValuesIdentical?: boolean;
};

const AmountPrefix: React.FC<React.PropsWithChildren<AmountPrefixProps>> = ({
  aggregationType,
  aggregatedValuesIdentical
}) => {
  const { t } = useLingui();
  const prefix = formatAmountPrefix(aggregationType, aggregatedValuesIdentical);
  return prefix ? <React.Fragment>{t(prefix)} </React.Fragment> : null;
};

const formatAmountPrefix = (
  aggregationType: AggregationType,
  aggregatedValuesIdentical?: boolean
) => {
  return match([aggregationType, aggregatedValuesIdentical])
    .with(["min", true], () => msg({ id: `token-value-at` }))
    .with(["min", P.union(false, P.nullish)], () =>
      msg({ id: `token-value-from` })
    )
    .with([P.any, true], () => null)
    .with(["max", P.any], () => msg({ id: `token-value-up-to` }))
    .otherwise(() => null);

  // if (aggregationType === "min")
  //   return i18n._({
  //     id: aggregatedValuesIdentical ? t`token-value-at` : t`token-value-from`
  //   });
  // if (aggregatedValuesIdentical) return null;
  // if (aggregationType === "max") return i18n._({ id: t`token-value-up-to` });
  // return null;
};

const dateFormatOptions: FormatDateFormat = {
  dateStyle: "long"
};

function formattedValue(
  i18n: ReturnType<typeof useLingui>["i18n"],
  _placeholder: string,
  value: TokenValueEncoded,
  currentCurrency: Currency | undefined
): JSX.Element {
  return match(value)
    .with({ type: "date" }, (v) => (
      <DateOnlyFormat value={toUTCDate(v.date)} format={dateFormatOptions} />
    ))
    .with({ type: "percentage" }, (v) => (
      <React.Fragment>{formatPercentage(v)}</React.Fragment>
    ))
    .with({ type: "amount" }, (v) => {
      const formattedValue = i18n.number(
        v.amount,
        numberFormatOptions(v.currency)
      );

      const extendedUSDCurrencySymbol =
        v.currency == "USD" && currentCurrency != "USD";
      const extendedFormattedValue = extendedUSDCurrencySymbol
        ? formattedValue.replace("$", "US$")
        : formattedValue;
      return (
        <React.Fragment>
          <AmountPrefix
            aggregationType={v.aggregationType}
            aggregatedValuesIdentical={v.aggregatedValuesIdentical ?? undefined}
          />
          {extendedFormattedValue}
        </React.Fragment>
      );
    })
    .with({ type: "string" }, (v) => <React.Fragment>{v.value}</React.Fragment>)
    .otherwise(() => <React.Fragment></React.Fragment>);
}

const formatPercentage = (token: TokenValuePercentage): string =>
  `${token.percentage}%`;

interface PlaceholderValuesFormatterProps {
  placeholder: string;
  value: TokenValueEncoded;
}

const PlaceholderValuesFormatter: React.FC<
  React.PropsWithChildren<PlaceholderValuesFormatterProps>
> = ({ placeholder, value }) => {
  const geolocation = useGeolocation();
  const { i18n } = useLingui();

  if (!value && isPresent(process.env.SANITY_API_TOKEN))
    return <React.Fragment>{placeholder}</React.Fragment>;
  if (!value) return <React.Fragment> </React.Fragment>;

  return (
    <React.Fragment>
      {formattedValue(i18n, placeholder, value, geolocation.defaultCurrency)}
    </React.Fragment>
  );
};

export default PlaceholderValuesFormatter;

export function renderPlaceholderValue(
  placeholder: string,
  value: TokenValueEncoded
): ReactNode {
  return (
    <PlaceholderValuesFormatter
      key={`${placeholder}`}
      placeholder={placeholder}
      value={value}
    />
  );
}
