import { isSSR } from "src/utils/ssr";

export const getCookie = (cookieName: string) =>
  isSSR()
    ? undefined
    : window.document.cookie
        .split("; ")
        .find((row) => row.startsWith(`${cookieName}=`))
        ?.split("=")[1];

type CookieOptions = {
  domain?: string;
  sameSite?: "strict" | "lax" | "none";
  maxAge?: number;
  path?: string;
};
export const setCookie = (
  cookieName: string,
  value: string,
  options: CookieOptions = {},
  disableWildCardDomain = false
) => {
  if (isSSR()) return;
  const {
    domain = disableWildCardDomain
      ? window.location.hostname
      : cookieDomain(window.location.hostname),
    sameSite,
    maxAge,
    path
  } = options;
  const cookie = [
    `${cookieName}=${value}`,
    domain ? `domain=${domain}` : undefined,
    `samesite=${sameSite ?? "strict"}`,
    `path=${path ?? "/"}`,
    maxAge ? `max-age=${maxAge}` : undefined
  ]
    .filter((x) => x !== undefined)
    .join("; ");

  window.document.cookie = cookie;
};

export const deleteCookie = (cookieName: string) =>
  setCookie(cookieName, "", { maxAge: -1 });

export const cookieDomain = (domain: string) => {
  const levels = domain.split(".");
  const keepAtLeastTwoLevels = Math.min(1 - levels.length, -2);
  return levels.slice(keepAtLeastTwoLevels).join(".");
};
