import React from "react";
import { GatsbyBrowser, Script, ScriptStrategy } from "gatsby";
import {
  GatsbyPlugingSSCAnalyticsCodeOptions,
  generateSscAnalyticsCore
} from "./utils";

export const wrapRootElement: GatsbyBrowser["wrapPageElement"] = (
  { element },
  pluginOptions: GatsbyPlugingSSCAnalyticsCodeOptions
) => {
  return (
    <React.Fragment>
      <Script
        id="ssc-analytics-core"
        key="ssc-analytics-core"
        strategy={ScriptStrategy.idle}
        dangerouslySetInnerHTML={{
          __html: generateSscAnalyticsCore(
            pluginOptions.src,
            pluginOptions.qaSrc
          )
        }}
      />
      {element}
    </React.Fragment>
  );
};
