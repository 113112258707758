import {
  ABTestExperiment,
  activeExperiments,
  ExperimentTag,
  experimentFromUrl,
  segmentFromUrl,
  urlMatch,
  SegmentId
} from "src/ab-test-experiments/experiments";
import { isPresent } from "src/utils/checks";
import { experimentActiveInCurrentCountry } from "../edge-lambda/countries";

export function allActiveExperimentTags(
  experiments: readonly ABTestExperiment[],
  history: ExperimentTag[],
  url: string | undefined,
  userCountry?: string
): ExperimentTag[] {
  return experiments
    .filter(isMatching(url))
    .filter(isActiveInCountry(userCountry))
    .map(targetExperimentTag(history))
    .filter(isDefined);
}

export function pageExperimentTagFor(
  url: URL,
  countryCode: string | undefined
): ExperimentTag | undefined {
  const activeExperimentsInCountry = activeExperiments.filter(
    isActiveInCountry(countryCode)
  );
  const experiment = experimentFromUrl(
    url.pathname,
    activeExperimentsInCountry
  );
  if (!experiment) return undefined;
  const segment = segmentFromUrl(experiment, url.pathname);
  if (!segment) return undefined;

  return { experimentId: experiment.id, segmentId: segment };
}

export const isDisabledOrA = (segment: SegmentId | undefined) =>
  !segment || segment === "a";

const isDefined = <T>(value: T | undefined): value is T => value !== undefined;

const isMatching = (url: string | undefined) => (e: ABTestExperiment) =>
  e._type == "component" ||
  (isPresent(url) && urlMatch(new URL(url).pathname, e));

const isActiveInCountry =
  (country: string | undefined) => (e: ABTestExperiment) =>
    experimentActiveInCurrentCountry(e, country);

const targetExperimentTag =
  (history: ExperimentTag[]) => (experiment: ABTestExperiment) =>
    history.find((h) => h.experimentId === experiment.id);
