import React from "react";
import { Script } from "gatsby";
import { oneLine } from "common-tags";
import {
  generateDefaultDataLayer,
  generateGTM,
  GTMPluginOptions
} from "./utils";

type Props = GTMPluginOptions & {
  //
};

const RootElement: React.FC<Props> = ({
  id,
  includeInDevelopment = false,
  gtmAuth,
  gtmPreview,
  defaultDataLayer,
  dataLayerName = `dataLayer`,
  selfHostedOrigin = `https://www.googletagmanager.com`
}) => {
  if (defaultDataLayer) {
    defaultDataLayer = {
      type: typeof defaultDataLayer,
      value: defaultDataLayer
    };

    if (defaultDataLayer.type === `function`) {
      defaultDataLayer.value = defaultDataLayer.value.toString();
    }
  }

  const environmentParamStr =
    gtmAuth && gtmPreview
      ? oneLine`
          &gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x
        `
      : ``;

  let defaultDataLayerCode = ``;
  if (defaultDataLayer) {
    defaultDataLayerCode = generateDefaultDataLayer(
      defaultDataLayer,
      dataLayerName
    );
  }

  selfHostedOrigin = selfHostedOrigin.replace(/\/$/, ``);

  return (
    <Script
      strategy="idle"
      id="plugin-google-tagmanager"
      key="plugin-google-tagmanager"
      dangerouslySetInnerHTML={{
        __html: oneLine`
          ${defaultDataLayerCode}
          ${generateGTM({
            id,
            environmentParamStr,
            dataLayerName,
            selfHostedOrigin
          })}`
      }}
    />
  );
};

export default RootElement;
