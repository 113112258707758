import { stringPresent } from "src/utils/strings";
import { useFetchApi, UseFetchDataResponse } from "./useFetchApi";

export type LiveSanityContentResponse<T> = {
  query?: string;
  result?: T;
};

export type UseLiveSanityContentResponse<T> = UseFetchDataResponse<
  LiveSanityContentResponse<T>
>;

export const sanityLocalized = (name: TemplateStringsArray) =>
  `'${name}': {'localized': coalesce(${name}[$lang],${name}["en"])}`;

export default function useLiveSanityContent<T>(
  query?: string,
  params?: Record<string, string>
): UseLiveSanityContentResponse<T> {
  const sanityAPI = `https://${process.env.GATSBY_SANITY_PROJECT_ID}.apicdn.sanity.io/v2021-03-29/data/query/${process.env.GATSBY_SANITY_DATASET}`;

  const quotedExtraParams = params
    ? Object.keys(params).reduce((acc, key) => {
        const value = params[key];
        if (stringPresent(value)) acc[key] = `"${value}"`;

        return acc;
      }, {})
    : {};

  const queryparams = new URLSearchParams({
    ...quotedExtraParams,
    query: query || ""
  });

  return useFetchApi<LiveSanityContentResponse<T>>(
    query ? `${sanityAPI}?${queryparams}` : undefined
  );
}
