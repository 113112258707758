import "../style/style.scss";

import React from "react";
import { PageProps, Slice } from "gatsby";
import { PageContext } from "types/pageContext";
import { isPresent } from "src/utils/checks";
import ErrorBoundary from "components/ErrorBoundary";
import { BlockContentComponentsProvider } from "@silversea-ssc/ui-components-react";

import { customBlockContentComponents } from "components/BlockContent/components";
import { offerPlaceholderResolver } from "components/PlaceholderValueFormatter/resolver";
import MaybeKeepBooking from "components/KeepBooking/MaybeKeepBooking";
import { layoutConfigFor } from "./layouts";
import ChatJSIntegration from "src/utils/chat.init";

const DefaultLayout: React.FC<
  React.PropsWithChildren<PageProps<unknown, PageContext>>
> = ({ pageContext, children }) => {
  const layoutConfig = layoutConfigFor(pageContext.layoutType);
  const alternateLinks = pageContext.alternateLinks?.filter(isPresent) || [];
  const hideBookCTA = pageContext.hideNavBarBook === true;
  const stickyHeader = layoutConfig.stickyHeader;
  const layoutOptions = pageContext.layoutOptions;
  const hideKeepBooking = pageContext.hideKeepBooking === true;

  return (
    <React.Fragment>
      <a href="#main" className="skip-to-main-content-link">
        Skip to main content
      </a>
      <header
        className={stickyHeader ? "main-sticky" : "mobile-only-sticky"}
        data-content-name="header"
        data-main-header
      >
        <ErrorBoundary>
          <Slice
            allowEmpty
            alias="header"
            alternateLinks={alternateLinks}
            hideBookCTA={hideBookCTA}
            hideRaqCTA={!!layoutOptions?.hideHeaderRAQ}
          />
        </ErrorBoundary>
      </header>
      <ErrorBoundary>
        <BlockContentComponentsProvider
          components={customBlockContentComponents}
          placeholderResolver={offerPlaceholderResolver}
        >
          <main id="main">
            {children}
            {!hideKeepBooking && (
              <React.Suspense>
                <MaybeKeepBooking />
              </React.Suspense>
            )}
          </main>
        </BlockContentComponentsProvider>
      </ErrorBoundary>
      <ErrorBoundary>
        <Slice alias="footer" allowEmpty />
      </ErrorBoundary>
      <ErrorBoundary>
        <Slice alias="subscribe-to-newsletter-popup" allowEmpty />
      </ErrorBoundary>
      <ChatJSIntegration />
    </React.Fragment>
  );
};

export default DefaultLayout;
