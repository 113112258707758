import { jsonFetch } from "../../../ssc-api/jsonFetch";
import { Geolocation } from "./geolocation";
import { Currency } from "../currency";
import { isSSR } from "../../ssr";
import { GeolocationApiResponse } from "@silversea-ssc/dotcom-api-types/Geolocation";

export const geolocationAPIPath = "/ViewerGeoLocation";

type GeolocationApiFetch = (url: string) => Promise<GeolocationApiResponse>;

const getApiRequestUrl = (
  apiBaseUrl: string,
  overrideCountryCode: string | undefined
): string => {
  let apiURL = `${apiBaseUrl}${geolocationAPIPath}`;
  if (overrideCountryCode) apiURL += `?countryCode=${overrideCountryCode}`;
  return apiURL;
};

const mapApiResponse = (
  result: GeolocationApiResponse
): Omit<GeolocationApiResponse, "iso3Code"> & { countryCodeISO3: string } => {
  const { iso3Code, ...others } = result;
  return {
    ...others,
    countryCodeISO3: iso3Code
  };
};

export const customLocationValuesFromSearch = (
  search: string
): {
  defaultCurrency?: Currency;
  countryCode?: string;
  date?: string;
  fake?: string;
} => {
  const urlParams = new URLSearchParams(search);
  const currency = urlParams.get("currency");
  const country = urlParams.get("country");
  const date = urlParams.get("date");
  const fake = urlParams.get("fake");

  return Object.assign(
    {},
    currency && { defaultCurrency: currency },
    country && { countryCode: country },
    date && { date: date },
    fake && { fake: fake }
  );
};

const customLocationValuesFromURL = (location: URL) =>
  customLocationValuesFromSearch(location.search);

function getPhoneDisclaimer(
  apiResponse: GeolocationApiResponse
): string | undefined {
  if (apiResponse.phone && apiResponse.phone.includes("-844-"))
    return "Calls cost 7p per minute plus your phone company’s access charge";

  return undefined;
}

export const loadGeolocation =
  (apiBaseUrl: string, fetch: GeolocationApiFetch) =>
  async (pageUrl: URL): Promise<Geolocation> => {
    const customValues = customLocationValuesFromURL(pageUrl);

    const apiResponse = await fetch(
      getApiRequestUrl(apiBaseUrl, customValues.countryCode)
    );
    const apiValues = mapApiResponse(apiResponse);
    return {
      ...apiValues,
      ...customValues,
      countryCodeOverridden: !!customValues.countryCode,
      phoneDisclaimer: getPhoneDisclaimer(apiResponse)
    };
  };

let currentGeolocationPromise: Promise<Geolocation>;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const apiBaseUrl = process.env.GATSBY_DIGITAL_API_BASE_URL!;
const fetch = (url: string) => jsonFetch<GeolocationApiResponse>(url);
const loadGeolocationFromApi = loadGeolocation(apiBaseUrl, fetch);
const getCurrentUrl = (window: Window) => new URL(window.location.toString());

export const getCurrentGeolocation = (): Promise<Geolocation> => {
  if (isSSR()) {
    console.debug("is ssr");
    currentGeolocationPromise = Promise.reject(
      "Cannot retrieve Geolocation on server."
    );
  } else if (!currentGeolocationPromise) {
    currentGeolocationPromise = loadGeolocationFromApi(getCurrentUrl(window));
  }
  return currentGeolocationPromise;
};
